import logo from './logo.svg';
import axios from 'axios';
import './App.css';
import React , {useEffect , useState} from 'react'
function App() {
  const [product , setProduct] = useState([])
  useEffect(() => {
      fetch("http://localhost:3000/product")
      .then( response => response.json())
      .then((data) => setProduct(data))
    }, []);

  function deleteProduct (productId){
    fetch(`http://localhost:3000/product/${productId}` , {
      method : "DELETE"
    })
    .then((res)=>res.json)
    .then((data)=>{console.log(data);})
  }

  return (
    <div className="App">
      {
        product.map((product)=>{
          return(
            <div
            key={product.id}
            onClick={()=>{deleteProduct(product.id)}}
            >
            <h3>{product.title}</h3>
            <p>{product.category}</p>
            <p>{product.price}</p>
            </div>
          )
        })
      }
      <button> Delet Product </button>
    </div>
  );
}

export default App;
